    /* /images/login/user.svg */
    :root.dark {
      --login-user: url('/images/login/user.svg');
      --shop-share-icon: url('/images/store/shopShare.svg');
      --message-other-arrow-icon: url('/images/chat/other.svg');
    }

    :root.light {
      --login-user: url('/images/login/userLight.svg');
      --shop-share-icon: url('/images/store/shopShareLight.svg');
      --message-other-arrow-icon: url('/images/chat/otherLight.svg');
    }
