.keen-slider:not([data-keen-slider-disabled]){-webkit-touch-callout:none;-webkit-tap-highlight-color:transparent;align-content:flex-start;display:flex;overflow:hidden;position:relative;touch-action:pan-y;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-khtml-user-select:none;width:100%}.keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide{min-height:100%;overflow:hidden;position:relative;width:100%}.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-reverse]{flex-direction:row-reverse}.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-v]{flex-wrap:wrap}
.container{
    width: 100%;
}
@media (min-width: 640px){
    .container{
        max-width: 640px;
    }
}
@media (min-width: 768px){
    .container{
        max-width: 768px;
    }
}
@media (min-width: 1024px){
    .container{
        max-width: 1024px;
    }
}
@media (min-width: 1280px){
    .container{
        max-width: 1280px;
    }
}
@media (min-width: 1536px){
    .container{
        max-width: 1536px;
    }
}
.pointer-events-none{
    pointer-events: none !important;
}
.static{
    position: static !important;
}
.fixed{
    position: fixed !important;
}
.absolute{
    position: absolute !important;
}
.relative{
    position: relative !important;
}
.top-12{
    top: 3rem !important;
}
.z-\[1\]{
    z-index: 1 !important;
}
.mx-auto{
    margin-left: auto !important;
    margin-right: auto !important;
}
.my-2{
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}
.my-4{
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}
.mb-2{
    margin-bottom: 0.5rem !important;
}
.mb-3{
    margin-bottom: 0.75rem !important;
}
.mb-4{
    margin-bottom: 1rem !important;
}
.ml-1{
    margin-left: 0.25rem !important;
}
.mr-2{
    margin-right: 0.5rem !important;
}
.mr-3{
    margin-right: 0.75rem !important;
}
.mt-1{
    margin-top: 0.25rem !important;
}
.mt-4{
    margin-top: 1rem !important;
}
.mt-5{
    margin-top: 1.25rem !important;
}
.mt-6{
    margin-top: 1.5rem !important;
}
.block{
    display: block !important;
}
.flex{
    display: flex !important;
}
.grid{
    display: grid !important;
}
.hidden{
    display: none !important;
}
.aspect-square{
    aspect-ratio: 1 / 1 !important;
}
.h-16{
    height: 4rem !important;
}
.h-28{
    height: 7rem !important;
}
.h-\[360px\]{
    height: 360px !important;
}
.h-\[400px\]{
    height: 400px !important;
}
.h-\[52px\]{
    height: 52px !important;
}
.h-\[92\%\]{
    height: 92% !important;
}
.h-auto{
    height: auto !important;
}
.h-full{
    height: 100% !important;
}
.max-h-\[200px\]{
    max-height: 200px !important;
}
.max-h-full{
    max-height: 100% !important;
}
.w-11\/12{
    width: 91.666667% !important;
}
.w-\[90\%\]{
    width: 90% !important;
}
.w-auto{
    width: auto !important;
}
.w-full{
    width: 100% !important;
}
.max-w-full{
    max-width: 100% !important;
}
.transform{
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
.cursor-pointer{
    cursor: pointer !important;
}
.resize{
    resize: both !important;
}
.flex-row{
    flex-direction: row !important;
}
.flex-col{
    flex-direction: column !important;
}
.flex-wrap{
    flex-wrap: wrap !important;
}
.items-center{
    align-items: center !important;
}
.justify-center{
    justify-content: center !important;
}
.gap-2{
    gap: 0.5rem !important;
}
.overflow-y-auto{
    overflow-y: auto !important;
}
.rounded{
    border-radius: 0.25rem !important;
}
.border{
    border-width: 1px !important;
}
.border-0{
    border-width: 0px !important;
}
.border-b{
    border-bottom-width: 1px !important;
}
.border-solid{
    border-style: solid !important;
}
.border-grey-custom{
    border-color: rgba(33, 33, 33, 0.08) !important;
}
.bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.object-contain{
    object-fit: contain !important;
}
.p-4{
    padding: 1rem !important;
}
.px-2{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}
.py-2{
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}
.py-4{
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.text-left{
    text-align: left !important;
}
.text-sm{
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
}
.font-medium{
    font-weight: 500 !important;
}
.font-semibold{
    font-weight: 600 !important;
}
.opacity-10{
    opacity: 0.1 !important;
}
.opacity-30{
    opacity: 0.3 !important;
}
.opacity-50{
    opacity: 0.5 !important;
}
.shadow{
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.outline{
    outline-style: solid !important;
}
.blur{
    --tw-blur: blur(8px) !important;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.invert{
    --tw-invert: invert(100%) !important;
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.filter{
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}


:root {
    --white: #212121;
    /* --primary: #ffffff; */
    --primary1: #211E1F;
    --primary2: #9e9e9e;
    --primary3: #616161;
    --primary4: #BDBDBD;
    --primary5: #ffffff;
    --primary6: #212121;
    --primary7: #707070;
    --primary8: #414141;
    --primary9: #222222;
    --primary10: #3c3c3c;
    --primary11: #f5f5f5;
    --primary12: #eeeeee;
    --red: #F44336;
    --active: #fc427b;
    --secondary1: #FAEFC2;
    /* --secondary2: #FFB74D; */
    /* --grey: #212121; */
    --green: #07bc4c;
    --green1: #2BB143;
    --yellow: #212121;
    --window-grey: #fbfcfb;
    --light-grey: #a3a3a3;
    --dark-grey: #8a8585;
    --black15: rgba(33, 33, 33, 0.15);
    --black02: rgba(33, 33, 33, 0.02);
    --black05: rgba(33, 33, 33, 0.05);
    --green2: #32BA46;

    --secondary: #ffffff;
    --primary: #F5603F;
    --primary20: rgba(245, 96, 63, 0.2);
    --black: #212121;
    --black1: #000000;
    --profile-bg: #ffffff;
    --black110: rgba(0, 0, 0, 0.1);
    --black150: rgba(0, 0, 0, 0.5);
    --black006: rgba(0, 0, 0, 0.06);
    --black10: rgba(33, 33, 33, 0.1);
    --black20: rgba(33, 33, 33, 0.2);
    --black40: rgba(33, 33, 33, 0.4);
    --black50: rgba(33, 33, 33, 0.5);
    --black60: rgba(33, 33, 33, 0.6);
    --black70: rgba(33, 33, 33, 0.7);
    --black80: rgba(33, 33, 33, 0.8);
    --secondary-grey: #E7E8E3;
    --grey: #f4f4f4;
    --primary13: #f8f8f8;
    --grey1: #E5E5E5;
    --grey4: rgba(33, 33, 33, 0.04);
    --grey10: #FAFAFA;
    --blue: #060B29;
    --h1-font-size: 1.5rem !important;
    --h2-font-size: 1.375rem !important;
    --h3-font-size: 1.25rem !important;
    --h4-font-size: 1.125rem !important;
    --h5-font-size: 1rem !important;
    --d-font-size: 0.875rem !important;
    --m-font-size: 0.75rem !important;
    --s-font-size: 0.625rem !important;
    --xs-font-size: 0.5rem !important;
    --xxs-font-size: 0.4375rem !important;
}

.textCenter {
    text-align: center;
}

.textRight {
    text-align: right;
}

.textLeft {
    text-align: left;
}

.center {
    display: flex;
    align-items: center;
}

.vertical {
    display: flex;
    flex-direction: column;
}

.vertical-center {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.hor-center {
    align-items: center;
    justify-content: center;
    display: flex;
}

.flexBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flexSpace {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.feedAvatar {
    width: 35px !important;
    height: 35px !important;
}

.flexStart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.flexEnd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.marginSTopBottom {
    margin: var(--s-spacing) 0 !important;
}

.marginXSTopBottom {
    margin: var(--xs-spacing) 0 !important;
}

.marginMLeftRight {
    margin: 0 var(--m-spacing) !important;
}

.marginMTopBottom {
    margin: var(--m-spacing) 0 !important;
}

.wrapCenter {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.bold {
    font-weight: var(--bold);
}

.semibold {
    font-weight: var(--semi-bold);
}

.font_bio {
    font-weight: var(--bold);
    font-size: var(--s-font-size) !important;
}

.medium {
    font-weight: var(--medium-bold);
}

.light {
    font-weight: 400 !important;
}

.regular {
    font-weight: 400;
}

body {
    margin: 0;
    font-family: 'SfUiText', serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--secondary);
}

.Roboto {
    font-family: Roboto !important;
}

code {
    font-family: 'SfUiText', sans-serif;
}

.white {
    color: var(--white) !important;
}

.green {
    color: var(--green) !important;
}

.green1 {
    color: var(--green1) !important;
}

.red {
    color: var(--secondary) !important;
}

.grey {
    color: var(--grey) !important;
}

.active {
    color: var(--active) !important;
}

.secondary {
    color: var(--secondary) !important;
}

.yellow {
    color: var(--yellow)
}

.primary {
    color: var(--primary) !important;
}

.windo-grey {
    color: var(--window-grey);
}

.dark-grey {
    color: var(--dark-grey) !important;
}

.profileDp {
    width: 100px !important;
    height: 100px !important;
}

.thirdWidth {
    width: calc(100% / 3);
}

.noPadBottom {
    padding-bottom: 0 !important;
}

.opacityhalf {
    opacity: 0.5;
}

.fullHeight {
    height: 100%;
}

.fullWidth {
    width: 100% !important;
}

.halfWidth {
    width: 50%;
}

@media screen and (orientation: landscape) {
    video {
        -o-object-fit: contain !important;
           object-fit: contain !important;
    }
}

.hide {
    display: none !important;
}

.red {
    color: rgb(177, 71, 71);
}

.green {
    color: rgb(47, 121, 47);
}

.ellipsis100 {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.textCapital {
    text-transform: capitalize !important;
}

.fs12 {
    font-size: var(--m-font-size);
}

.fs10 {
    font-size: var(--s-font-size);
}

.fs14 {
    font-size: var(--d-font-size) !important;
}

.fs16 {
    font-size: var(--h5-font-size);
}

.noMinWidth {
    min-width: 0 !important;
}

.noPadding {
    padding: 0 !important;
}

.noMargin {
    margin: 0 !important;
}

.paddingSLeft {
    padding-left: var(--s-spacing) !important;
}

.strikeLine {
    text-decoration: line-through !important;
}

.textDecNone {
    text-decoration: none !important;
}

::-webkit-scrollbar {
    display: none;
}

html {
    -ms-overflow-style: none;
    scrollbar-width: none;

    font-size: 16px
}

@media all and (min-width: 768px) {

html {
        font-size: 18px
}
    }

html {
    height: 100vh;
}

body {
    height: 100vh;
}

.kep-login-facebook {
    border: 0 !important;
    padding: 0 !important;
    border-radius: 4px !important;
}

.MuiOutlinedInput-multiline {
    padding: 0 !important;
}

.MuiFilledInput-underline.Mui-disabled:before {
    border-bottom-style: none !important;
}

.MuiInput-underline:after,
.MuiInput-underline:before {
    border-bottom: none !important;
}

@media all and (min-width: 420px) {

.mobile {
        display: none !important
}
    }

@media all and (max-width: 420px) {

.desktop {
        display: none !important
}
    }

/* Phoenix - components package override styles */

button[class^="Clickable-module_button"],
button[class^="button-module_button"],
input[class^="Input-module_text-form-input"],
input[class^="Phone_text-form-input"] {
    font-family: inherit;
}

label[class^="Input-module_form-group"] {
    background: transparent !important;
}

.max20Ch{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    max-width: 20ch;
    text-overflow: ellipsis;
}
.max10Ch{
    max-width: 10ch;
    text-overflow: ellipsis;
}
@media (min-width: 768px){
    .md\:w-auto{
        width: auto !important;
    }
    .md\:items-center{
        align-items: center !important;
    }
    .md\:gap-4{
        gap: 1rem !important;
    }
}
@media (min-width: 1024px){
    .lg\:mx-auto{
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .lg\:gap-0{
        gap: 0px !important;
    }
}

    /* /images/login/user.svg */
    :root.dark {
      --login-user: url('/images/login/user.svg');
      --shop-share-icon: url('/images/store/shopShare.svg');
      --message-other-arrow-icon: url('/images/chat/other.svg');
    }

    :root.light {
      --login-user: url('/images/login/userLight.svg');
      --shop-share-icon: url('/images/store/shopShareLight.svg');
      --message-other-arrow-icon: url('/images/chat/otherLight.svg');
    }

.Snackbar_root__js32T {
    z-index: 99999999;
    padding: 16px;
    position: fixed;
    bottom:50px;
    left: 0;


}


.Snackbar_center__u9PgR {
    display: flex;
    align-items: center;
    font-size: var(--d-font-size) !important;
    -webkit-animation: Snackbar_slide__foDNi 0.5s linear;
            animation: Snackbar_slide__foDNi 0.5s linear;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    color: var(--primary5) !important;
    background-color: var(--primary6) !important;
    padding: 0 var(--m-spacing) !important;
}

.Snackbar_red__eoHdk {
    color: var(--active) !important;
}

@-webkit-keyframes Snackbar_slide__foDNi {
    0%{
        position: relative;
        left: -45%;
    }
    25%{
        position: relative;
        left: -30%;
    }
    50%{
        position: relative;
        left: -15px;
    }
    75%{
        position: relative;
        left: 0;
    }
    100%{
    }
  }

@keyframes Snackbar_slide__foDNi {
    0%{
        position: relative;
        left: -45%;
    }
    25%{
        position: relative;
        left: -30%;
    }
    50%{
        position: relative;
        left: -15px;
    }
    75%{
        position: relative;
        left: 0;
    }
    100%{
    }
  }
.Loading_container__Pm0en {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background: rgba(255, 255, 255, 0.2);
}

.Loading_loader__vtOEv {
    padding: var(--m-spacing);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    border-radius: var(--border-radius);
}

.Loading_image__Fcp2P {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid var(--primary);
    width: 30px;
    height: 30px;
    -webkit-animation: Loading_spin__iD36R 1s linear infinite; /* Safari */
    animation: Loading_spin__iD36R 1s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes Loading_spin__iD36R {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes Loading_spin__iD36R {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.Error_img__bMEIF {
    max-width: 100%;
}

.Error_container__k04Cc {
    background: white;
    height: calc(100vh - 32px);
    padding: var(--l-spacing);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Error_errorContainer__6EC_2 {
    text-align: center;
    margin: var(--xl-spacing) 0;
}

.Error_h1__GrHlW {
    font-weight: var(--semi-bold);
    font-size: var(--h3-font-size);
}

.Error_errorMessage__2kBAR {
    margin: var(--m-spacing) 0;
    font-weight: var(--semi-bold);
    font-size: var(--m-font-size);
    color: var(--black60);
}

.Error_button__eigjl {
    border: 0;
    outline: 0;
    background: var(--yellow);
    padding: var(--s-spacing) var(--m-spacing);
    border-radius: var(--border-radius);
    font-size: var(--d-font-size);
}

