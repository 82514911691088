@tailwind components;
@tailwind utilities;

@import "~keen-slider/keen-slider.min.css";


@custom-media --phone screen and (max-width: 419px);

@custom-media --desktop screen and (min-width: 420px);


:root {
    --white: #212121;
    /* --primary: #ffffff; */
    --primary1: #211E1F;
    --primary2: #9e9e9e;
    --primary3: #616161;
    --primary4: #BDBDBD;
    --primary5: #ffffff;
    --primary6: #212121;
    --primary7: #707070;
    --primary8: #414141;
    --primary9: #222222;
    --primary10: #3c3c3c;
    --primary11: #f5f5f5;
    --primary12: #eeeeee;
    --red: #F44336;
    --active: #fc427b;
    --secondary1: #FAEFC2;
    /* --secondary2: #FFB74D; */
    /* --grey: #212121; */
    --green: #07bc4c;
    --green1: #2BB143;
    --yellow: #212121;
    --window-grey: #fbfcfb;
    --light-grey: #a3a3a3;
    --dark-grey: #8a8585;
    --black15: rgba(33, 33, 33, 0.15);
    --black02: rgba(33, 33, 33, 0.02);
    --black05: rgba(33, 33, 33, 0.05);
    --green2: #32BA46;

    --secondary: #ffffff;
    --primary: #F5603F;
    --primary20: rgba(245, 96, 63, 0.2);
    --black: #212121;
    --black1: #000000;
    --profile-bg: #ffffff;
    --black110: rgba(0, 0, 0, 0.1);
    --black150: rgba(0, 0, 0, 0.5);
    --black006: rgba(0, 0, 0, 0.06);
    --black10: rgba(33, 33, 33, 0.1);
    --black20: rgba(33, 33, 33, 0.2);
    --black40: rgba(33, 33, 33, 0.4);
    --black50: rgba(33, 33, 33, 0.5);
    --black60: rgba(33, 33, 33, 0.6);
    --black70: rgba(33, 33, 33, 0.7);
    --black80: rgba(33, 33, 33, 0.8);
    --secondary-grey: #E7E8E3;
    --grey: #f4f4f4;
    --primary13: #f8f8f8;
    --grey1: #E5E5E5;
    --grey4: rgba(33, 33, 33, 0.04);
    --grey10: #FAFAFA;
    --blue: #060B29;
    --h1-font-size: 1.5rem !important;
    --h2-font-size: 1.375rem !important;
    --h3-font-size: 1.25rem !important;
    --h4-font-size: 1.125rem !important;
    --h5-font-size: 1rem !important;
    --d-font-size: 0.875rem !important;
    --m-font-size: 0.75rem !important;
    --s-font-size: 0.625rem !important;
    --xs-font-size: 0.5rem !important;
    --xxs-font-size: 0.4375rem !important;
}

.textCenter {
    text-align: center;
}

.textRight {
    text-align: right;
}

.textLeft {
    text-align: left;
}

.center {
    display: flex;
    align-items: center;
}

.vertical {
    display: flex;
    flex-direction: column;
}

.vertical-center {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.hor-center {
    align-items: center;
    justify-content: center;
    display: flex;
}

.flexBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flexSpace {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.feedAvatar {
    width: 35px !important;
    height: 35px !important;
}

.flexStart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.flexEnd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.marginSTopBottom {
    margin: var(--s-spacing) 0 !important;
}

.marginXSTopBottom {
    margin: var(--xs-spacing) 0 !important;
}

.marginMLeftRight {
    margin: 0 var(--m-spacing) !important;
}

.marginMTopBottom {
    margin: var(--m-spacing) 0 !important;
}

.wrapCenter {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.bold {
    font-weight: var(--bold);
}

.semibold {
    font-weight: var(--semi-bold);
}

.font_bio {
    font-weight: var(--bold);
    font-size: var(--s-font-size) !important;
}

.medium {
    font-weight: var(--medium-bold);
}

.light {
    font-weight: 400 !important;
}

.regular {
    font-weight: 400;
}

body {
    margin: 0;
    font-family: 'SfUiText', serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--secondary);
}

.Roboto {
    font-family: Roboto !important;
}

code {
    font-family: 'SfUiText', sans-serif;
}

.white {
    color: var(--white) !important;
}

.green {
    color: var(--green) !important;
}

.green1 {
    color: var(--green1) !important;
}

.red {
    color: var(--secondary) !important;
}

.grey {
    color: var(--grey) !important;
}

.active {
    color: var(--active) !important;
}

.secondary {
    color: var(--secondary) !important;
}

.yellow {
    color: var(--yellow)
}

.primary {
    color: var(--primary) !important;
}

.windo-grey {
    color: var(--window-grey);
}

.dark-grey {
    color: var(--dark-grey) !important;
}

.profileDp {
    width: 100px !important;
    height: 100px !important;
}

.thirdWidth {
    width: calc(100% / 3);
}

.noPadBottom {
    padding-bottom: 0 !important;
}

.opacityhalf {
    opacity: 0.5;
}

.fullHeight {
    height: 100%;
}

.fullWidth {
    width: 100% !important;
}

.halfWidth {
    width: 50%;
}

@media screen and (orientation: landscape) {
    video {
        object-fit: contain !important;
    }
}

.hide {
    display: none !important;
}

.red {
    color: rgb(177, 71, 71);
}

.green {
    color: rgb(47, 121, 47);
}

.ellipsis100 {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.textCapital {
    text-transform: capitalize !important;
}

.fs12 {
    font-size: var(--m-font-size);
}

.fs10 {
    font-size: var(--s-font-size);
}

.fs14 {
    font-size: var(--d-font-size) !important;
}

.fs16 {
    font-size: var(--h5-font-size);
}

.noMinWidth {
    min-width: 0 !important;
}

.noPadding {
    padding: 0 !important;
}

.noMargin {
    margin: 0 !important;
}

.paddingSLeft {
    padding-left: var(--s-spacing) !important;
}

.strikeLine {
    text-decoration: line-through !important;
}

.textDecNone {
    text-decoration: none !important;
}

::-webkit-scrollbar {
    display: none;
}

html {
    -ms-overflow-style: none;
    scrollbar-width: none;

    font-size: 16px;

    @media all and (min-width: 768px) {
        font-size: 18px;
    }
}

html {
    height: 100vh;
}

body {
    height: 100vh;
}

.kep-login-facebook {
    border: 0 !important;
    padding: 0 !important;
    border-radius: 4px !important;
}

.MuiOutlinedInput-multiline {
    padding: 0 !important;
}

.MuiFilledInput-underline.Mui-disabled:before {
    border-bottom-style: none !important;
}

.MuiInput-underline:after,
.MuiInput-underline:before {
    border-bottom: none !important;
}

.mobile {
    @media all and (min-width: 420px) {
        display: none !important;
    }
}

.desktop {
    @media all and (max-width: 420px) {
        display: none !important;
    }
}

/* Phoenix - components package override styles */

button[class^="Clickable-module_button"],
button[class^="button-module_button"],
input[class^="Input-module_text-form-input"],
input[class^="Phone_text-form-input"] {
    font-family: inherit;
}

label[class^="Input-module_form-group"] {
    background: transparent !important;
}

.max20Ch {
    @apply line-clamp-1 max-w-[20ch] text-ellipsis;
}
.max10Ch {
    @apply max-w-[10ch] text-ellipsis;
}
