.root {
    z-index: 99999999;
    padding: 16px;
    position: fixed;
    bottom:50px;
    left: 0;


}


.center {
    display: flex;
    align-items: center;
    font-size: var(--d-font-size) !important;
    animation: slide 0.5s linear;
    animation-iteration-count: 1;
    color: var(--primary5) !important;
    background-color: var(--primary6) !important;
    padding: 0 var(--m-spacing) !important;
}

.red {
    color: var(--active) !important;
}

@keyframes slide {
    0%{
        position: relative;
        left: -45%;
    }
    25%{
        position: relative;
        left: -30%;
    }
    50%{
        position: relative;
        left: -15px;
    }
    75%{
        position: relative;
        left: 0;
    }
    100%{
    }
  }