.img {
    max-width: 100%;
}

.container {
    background: white;
    height: calc(100vh - 32px);
    padding: var(--l-spacing);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.errorContainer {
    text-align: center;
    margin: var(--xl-spacing) 0;
}

.h1 {
    font-weight: var(--semi-bold);
    font-size: var(--h3-font-size);
}

.errorMessage {
    margin: var(--m-spacing) 0;
    font-weight: var(--semi-bold);
    font-size: var(--m-font-size);
    color: var(--black60);
}

.button {
    border: 0;
    outline: 0;
    background: var(--yellow);
    padding: var(--s-spacing) var(--m-spacing);
    border-radius: var(--border-radius);
    font-size: var(--d-font-size);
}
